import styled from 'styled-components';

export const Navigation = styled.nav`
  text-align: center;
`;

export const NavigationList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const NavigationItem = styled.li`
  margin: 0 0 0 20px;
  padding: 0;
  color: ${props => props.theme.colors.light};
  font-size: 20px;
  font-family: ${props => props.theme.fonts.heading};

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;

    &.active {
      border-bottom: 1px solid ${props => props.theme.colors.light};
    }
  }
`;
