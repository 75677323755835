import styled from 'styled-components';

export const Header = styled.header`
  background: ${props => props.theme.colors.black};

  a {
    text-decoration: none;
  }
`;

export const Section = styled.section`
  @media only screen and (${props => props.theme.breakpoints.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.h1`
  margin: 30px 0;
  font-size: 32px;
  color: ${props => props.theme.colors.white};
`;
