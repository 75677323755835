import { normalize, lighten, darken, desaturate, saturate } from 'polished';

const primaryColor = '#B18FCF';
const secondaryColor = '#cfc08f';
const desaturatedColor = desaturate(0.3, primaryColor);

export const theme = {
  maxWidth: '1200px',
  fonts: {
    body: "'Raleway', sans-serif",
    heading: "'Roboto Slab', serif",
  },
  colors: {
    white: lighten(0.3, primaryColor),
    light: lighten(0.2, primaryColor),
    primary: primaryColor,
    dark: darken(0.3, desaturatedColor),
    black: darken(0.45, desaturatedColor),
    secondary: saturate(0.1, secondaryColor),
  },
  breakpoints: {
    small: 'min-width: 24em',
    medium: 'min-width: 50em',
    large: 'min-width: 73em',
  },
};

export const global = `
  * {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    outline: none;
    color: ${theme.colors.black}
    font-family: ${theme.fonts.body}
  }

  h1,h2,h3,h4 {
    font-family: ${theme.fonts.heading}
  }

  html,
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: ${theme.colors.white}
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * a:active:focus,
  * button:active:focus {
    outline: none;
  }
`;
