import * as React from 'react';
import { Link } from 'gatsby';
import * as Styled from './Navigation.style';

export interface INavigationProps {
  navItems: NavigationItem[];
}

interface NavigationItem {
  linkText: string;
  link: string;
}

const Navigation: React.FC<INavigationProps> = ({ navItems }) => (
  <Styled.Navigation>
    <Styled.NavigationList>
      {navItems.map(navItem => (
        <Styled.NavigationItem key={navItem.link}>
          <Link activeClassName="active" to={navItem.link}>
            {navItem.linkText}
          </Link>
        </Styled.NavigationItem>
      ))}
    </Styled.NavigationList>
  </Styled.Navigation>
);

export default Navigation;
