import { useEffect, useState } from 'react';
import { gtag } from './useOptimizeActivate';

export function useOptimizeCallback(experimentID: string) {
  const [variant, setVariant] = useState('0');

  function implementExperiment(value: string) {
    setVariant(value);
  }

  useEffect(() => {
    gtag('event', 'optimize.callback', {
      name: experimentID,
      callback: implementExperiment,
    });
  }, []);

  return [variant, setVariant];
}
