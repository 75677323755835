import * as React from 'react';
import Header from '../Header';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { theme, global } from '../../theme';
import { normalize } from 'polished';

const Layout: React.FC = props => {
  const GlobalStyle = createGlobalStyle`
    ${normalize()}
    ${global}
  `;
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header />
        {props.children}
      </>
    </ThemeProvider>
  );
};

export default Layout;
