import { useEffect } from 'react';

export function gtag(event: string, eventType: string, obj: any = {}) {
  console.log('pushing args...', eventType);
  window.dataLayer.push(arguments);
}

export function useOptimizeActivate() {
  useEffect(() => {
    gtag('event', 'optimize.activate');
  }, []);
}
