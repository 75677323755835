import * as React from 'react';
import Container from '../Container';
import Navigation from '../Navigation';
import * as Styled from './Header.style';
import { Link } from 'gatsby';

const navItems = [
  {
    linkText: 'Home',
    link: '/',
  },
  {
    linkText: 'About',
    link: '/about',
  },
  {
    linkText: 'Style Guide',
    link: '/style-guide',
  },
  {
    linkText: 'Contact Us',
    link: '/contact',
  },
];

const Header: React.FC = () => (
  <Styled.Header>
    <Container>
      <Styled.Section>
        <Link to="/">
          <Styled.Title>Awesome Site</Styled.Title>
        </Link>
        <Navigation navItems={navItems} />
      </Styled.Section>
    </Container>
  </Styled.Header>
);

export default Header;
